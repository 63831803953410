import fetchWrapper from "../../../fetchWrapper";

export const UserListService = async (pageIndex) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users?page=${pageIndex}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(),
    });
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const { payload } = await response.json();
    return { payload };
  } catch (error) {}
};

export const UserByIDService = async (id) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/users/user/${id}`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const { payload } = await response.json();
    return { payload };
  } catch (error) {}
};

/*export const UserCreateService = async (payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const result = await response.json();
    const { success, code, data } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return { data };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};*/

export const UserCreateService = async (prueba) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/users/create`,
      JSON.stringify(prueba),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code, payload } = result;

    if (success === true) {
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export const UserUpdateService = async (id, payload) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/users/edit/${id}`,
      JSON.stringify(payload),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code, data } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export const UserDeleteService = async (id, payload) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/delete/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};
