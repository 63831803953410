import React from "react";
//import { useAuth } from "../../../context/index";
//const { token, login, logout } = useAuth();
const getAllCardPerDocumentUser = async (document, pageIndex = 1) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cards/cards_per_document_user_carnets/${document}?page=${pageIndex}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    // const { users } = await response.json();
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export { getAllCardPerDocumentUser };
