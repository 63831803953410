/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React from "react";
import { date } from "yup";

const url = process.env.REACT_APP_API_URL;

//consultar el endpoint que me retornara el listado de carnets o error si ocurre y retornar esos datos
const getCarnetUserPerFile = async (id, pageIndex) => {
  try {
    const response = await fetch(url + `/cards/get_more_info_per_file/${id}?page=${pageIndex}`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara la informacion de un usuario carnetizado por la id enviada del usuario o error si ocurre y retornar esos datos
const getUserId = async (idUser) => {
  try {
    const response = await fetch(url + `/card_users/card_user/${idUser}`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const { id, name, lastname, number_id, email, created_at } = await response.json();
    return { id, name, lastname, number_id, email, created_at };
  } catch (error) {}
};

//consultar el endpoint que me retornara el listado de tipos de carnet o error si ocurre y retornar esos datos
const getTypeCarnets = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + `/card_types/`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const {
      payload: { data, last_page, total, from, to },
    } = await response.json();
    return { data, last_page, total, from, to };
  } catch (error) {}
};

// Enviar notificaciones
const sendCards = async (id, only_not_notified) => {
  const requestData = {
    data_source_file_id: id,
    only_not_notified: only_not_notified,
  };

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cards/send_email`, {
      method: "POST", // Usar método POST para enviar datos en el cuerpo
      headers: {
        "Content-Type": "application/json",
        // Puedes incluir el token de autorización si es necesario
        // "Authorization": `Bearer ${accessToken}`,
      },
      body: JSON.stringify(requestData), // Convertir el objeto a JSON
    });
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    // Verificar si la respuesta es JSON
    const result = await response.json();

    // Verificar si la respuesta indica éxito
    if (response.ok) {
      return {
        status: 200,
        message: "Operación completada con éxito",
        data: result, // Incluyendo datos adicionales si es necesario
      };
    } else {
      // Si la respuesta no fue exitosa, lanzar un error con detalles
      throw new Error(result.message || "Error del servidor al realizar la solicitud");
    }
  } catch (error) {
    // Manejar cualquier tipo de error
    return {
      status: false,
      message: error.message || "Error del servidor al realizar la solicitud",
    };
  }
};

//consultar el endpoint que me retornara si el cambio de la vigencia de carnets fue exitoso o error si ocurre y retornar esos datos
const updatedCardValidity = async (editValidityCard) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cards/validity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(editValidityCard),
    });
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

const updatedStatusCarnet = async (id, statusCarnet) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cards/edit/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(statusCarnet),
    });

    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }

    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export {
  getCarnetUserPerFile,
  getUserId,
  getTypeCarnets,
  updatedCardValidity,
  updatedStatusCarnet,
  sendCards,
};
