import fetchWrapper from "../../../fetchWrapper";

//Recibe el arreglo de filtros con sus respectivos valores para convertirlos en una url usada para la peticion
const getPerFilter = async (pageIndex, pageSize, filters, Table) => {
  try {
    let url = `/${Table}/${Table}_per_filter?page=${pageIndex}&pageSize=${pageSize}`;
    if (filters && Array.isArray(filters)) {
      filters.forEach((filter) => {
        if (
          filter.type !== "expires_in" &&
          filter.type !== "created" &&
          filter.type !== "created_at" &&
          filter.type !== "updated_at"
        ) {
          {
            url += `&type_filter[]=${filter.type}&search[]=${filter.value}`;
          }
        }
        if (
          filter.type === "expires_in" ||
          filter.type === "created" ||
          filter.type === "created_at" ||
          filter.type === "updated_at"
        ) {
          url += `&type_date[]=${filter.type}&dateFrom[]=${filter.value.from}&dateUntil[]=${filter.value.until}`;
        }
      });
    }

    const response = await fetchWrapper(process.env.REACT_APP_API_URL + url);

    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }

    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to, success };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//Recibe el arreglo de filtros con sus respectivos valores para convertirlos en una url usada para la peticion
const getPerFilterPerFile = async (id, pageIndex, pageSize, filters, Table) => {
  const idFileOrDataSourceId = Table === "cards" ? "file_id" : "per_data_source";
  try {
    let url = `/${Table}/${Table}_per_filter_${idFileOrDataSourceId}/${id}?page=${pageIndex}&pageSize=${pageSize}`;
    if (filters && Array.isArray(filters)) {
      filters.forEach((filter) => {
        if (
          filter.type !== "expires_in" &&
          filter.type !== "created" &&
          filter.type !== "created_at" &&
          filter.type !== "updated_at"
        ) {
          {
            url += `&type_filter[]=${filter.type}&search[]=${filter.value}`;
          }
        }
        if (
          filter.type === "expires_in" ||
          filter.type === "created" ||
          filter.type === "created_at" ||
          filter.type === "updated_at"
        ) {
          url += `&type_date[]=${filter.type}&dateFrom[]=${filter.value.from}&dateUntil[]=${filter.value.until}`;
        }
      });
    }

    const response = await fetchWrapper(process.env.REACT_APP_API_URL + url);

    console.log(response.status);

    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }

    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to, success };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export { getPerFilter, getPerFilterPerFile };
