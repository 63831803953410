import React from "react";
import fetchWrapper from "../../../fetchWrapper";

//import { useAuth } from "../../../context/index";
//const { token, login, logout } = useAuth();

//consultar el endpoint que me retornara el listado de tipos de carnets o error si ocurre y retornar esos datos
const getCardTypes = async (pageIndex) => {
  try {
    const token = localStorage.getItem("token"); // Obtener el token del almacenamiento local
    const user = localStorage.getItem("user_id"); // Obtener el token del almacenamiento local
    //debugger;
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/card_types?page=${pageIndex}`
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    // const { users } = await response.json();
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data, last_page, total, from, to },
      } = result;
      return { data, last_page, total, from, to };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me retornara el listado de tipos de carnets o error si ocurre y retornar esos datos
const getCardTypesWithoutPagination = async (pageIndex) => {
  try {
    //debugger;
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/card_types/card_types_without_pagination`
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    // const { users } = await response.json();
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const { payload } = result;
      return result;
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me creara un tipo de carnet o error si ocurre y retornar esos datos
const createCardTypes = async (newCardType) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/card_types/create`,
      JSON.stringify(newCardType),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    /* const response = await fetch(`${process.env.REACT_APP_API_URL}/card_types/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newCardType),
    }); */
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return { data };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me editara por id un tipo de carnet especifico o error si ocurre y retornar esos datos
const updatedCardTypes = async (id, editTypeCard) => {
  try {
    const response = await fetchWrapper(
      `${process.env.REACT_APP_API_URL}/card_types/edit/${id}`,
      JSON.stringify(editTypeCard),
      "POST"
    );
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const {
        payload: { data },
      } = result;
      return { data };
    } else if (success === false) {
      const message = "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
    }
  } catch (error) {
    const message = "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

//consultar el endpoint que me eliminará un tipo de carnet por id o error si ocurre y retornar esos datos
const deleteCardTypes = async (id) => {
  try {
    const response = await fetchWrapper(`${process.env.REACT_APP_API_URL}/card_types/delete/${id}`);
    if (response.status === 429) {
      // Manejar el error de demasiadas peticiones
      // console.error('Error 429: Too Many Requests');
      // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
      // throw new Error('Too Many Requests');
      const message =
        "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
      const status = false;
      return { status, message };
    }
    //const response = await fetch(`${process.env.REACT_APP_API_URL}/card_types/delete/${id}`);
    // const { users } = await response.json();
    const result = await response.json();
    const { success, code } = result;
    if (success === true) {
      const message = "Eliminado exitosamente";
      const status = true;
      return { status, message };
    } else if (success === false) {
      const message = result.message || "Error del servidor al realizar la solicitud";
      const status = false;
      return { status, message };
      //throw new Error(`Error del servidor al realizar la solicitud`);
    }
  } catch (error) {
    const message = error.message || "Error del servidor al realizar la solicitud";
    const status = false;
    return { status, message };
  }
};

export {
  getCardTypes,
  createCardTypes,
  updatedCardTypes,
  deleteCardTypes,
  getCardTypesWithoutPagination,
};
